var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

!function () {
  "use strict";

  var e = {
    linear: function (e) {
      return e;
    },
    quadratic: function (e) {
      return Math.pow(e, 2);
    },
    swing: function (e) {
      return 0.5 - Math.cos(e * Math.PI) / 2;
    },
    circ: function (e) {
      return 1 - Math.sin(Math.acos(e));
    },
    bounce: function (e) {
      for (var t = 0, n = 1;; t += n, n /= 2) if (e >= (7 - 4 * t) / 11) return -Math.pow((11 - 6 * t - 11 * e) / 4, 2) + Math.pow(n, 2);
    }
  };

  function t(t, n, o, i, a) {
    var r, c;

    if (t) {
      if (r = i.name.replace("_", ""), c = parseInt(t), isNaN(c)) throw new TypeError("Failed to execute '".concat(r, "' on 'Element': parameter 1 ('duration') is not an number.')"));
      if (c < 0) throw new TypeError("Failed to execute '".concat(r, "' on 'Element': parameter 1 ('duration') is not a valid number.')"));
    }

    if (n) {
      if (r = i.name.replace("_", ""), c = parseFloat(n), isNaN(c)) throw new TypeError("Failed to execute '".concat(r, "' on 'Element': parameter 2 ('opacity') is not an number.')"));
      if (c < 0 || c > 1) throw new TypeError("Failed to execute '".concat(r, "' on 'Element': parameter 2 ('opacity') is not a valid number.')"));
    }

    if (o) {
      if (r = i.name.replace("_", ""), c = o, "string" != typeof o) throw new TypeError("Failed to execute '".concat(r, "' on 'Element': parameter ('easing') is not an string.')"));
      if ("function" != typeof e[c]) throw new TypeError("Failed to execute '".concat(r, "' on 'Element': parameter ('easing') is not a valid easing function.')"));
    }

    if (a && "function" != typeof a) throw r = i.name.replace("_", ""), new TypeError("Failed to execute '".concat(r, "' on 'Element': parameter ('complete') is not an function.')"));

    var l = function (e, t, n, o) {
      var i;
      return function a(r) {
        i || (i = r);
        var c = r - i,
            l = Math.min(c / e, 1);
        n(t(l)), c >= e ? o && o() : window.requestAnimationFrame(a);
      };
    }(t = window.matchMedia("(prefers-reduced-motion: reduce)").matches ? 1 : parseInt(t), e[o], i, a);

    window.requestAnimationFrame(l);
  }

  window.Element.prototype.fadeIn = function () {
    var e = this || _global,
        n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 250,
        o = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "linear",
        i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null,
        a = parseFloat(window.getComputedStyle(this || _global).opacity),
        r = 1,
        c = function (t) {
      e.style.opacity = a + (r - a) * t;
    };

    1 !== a && t(n, r, o, c, i);
  }, window.Element.prototype.fadeOut = function () {
    var e = this || _global,
        n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 250,
        o = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "linear",
        i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null,
        a = parseFloat(window.getComputedStyle(this || _global).opacity),
        r = 0,
        c = function (t) {
      e.style.opacity = a - a * t;
    };

    0 !== a && t(n, r, o, c, i);
  }, window.Element.prototype.fadeToggle = function () {
    var e = this || _global,
        n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 250,
        o = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "linear",
        i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null,
        a = parseFloat(window.getComputedStyle(this || _global).opacity),
        r = a < 0.5 ? 1 : 0,
        c = function (t) {
      e.style.opacity = a < 0.5 ? a + (r - a) * t : a - a * t;
    };

    t(n, r, o, c, i);
  }, window.Element.prototype.fadeTo = function () {
    var e = this || _global,
        n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 250,
        o = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : NaN,
        i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "linear",
        a = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : null,
        r = parseFloat(window.getComputedStyle(this || _global).opacity),
        c = parseFloat(o),
        l = function (t) {
      e.style.opacity = r + (c - r) * t;
    };

    r !== c && t(n, c, i, l, a);
  };
}();
export default {};